import  {useState} from 'react';

import {
  Button,Modal, ModalHeader, ModalBody, ModalFooter, Card, CardText, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input
} from 'reactstrap';

import {Link} from 'react-router-dom'
import { MobileExperiment } from './MobileExperiment';
import ReactTooltip from "react-tooltip";
import { useTranslation } from 'react-i18next';


export const MobilePathExperiment = (props: any) => {
  const { content, topic } = props;
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  //console.log("Path Experiment:", content);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal)};

  const renderExperiments = (id:any) => {
    return content["esperimenti"].map((experiment: any, index: number) => {
      return <MobileExperiment topic={topic} key={`PE_${id}__${experiment.id}_${index}`} content={experiment} />
    })
  }

  const renderDescrizione = () => {
    if (content["descrizione"]==null) return null;
    const descLen = content["descrizione"].length;
    if (descLen<150)
    return content["descrizione"];
    else return  (
            <div>
            <p>{content["descrizione"].substring(0,150)}   
            <Link to="#" onClick={toggle}>{` ... `}</Link> </p>
            </div>
            )
          }
  return (
    <>
    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{content["titolo"]}</ModalHeader>
        <ModalBody>
          {content["descrizione"]}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Ok</Button>
        </ModalFooter>
      </Modal>
    <Card  className="pl-0 pr-0" body outline color="secondary" style={{
      borderColor: "#007bff" , background:"#e8d4d4"
    }}>
     
        <CardTitle data-tip={t("Percorso")} className="pl-2" tag="h5">{content.titolo}
        <ReactTooltip />
        </CardTitle>
       <CardSubtitle className="pl-2" >{renderDescrizione()}</CardSubtitle>
      <CardBody>
        {renderExperiments(props.id)}
      </CardBody>
     
    </Card>
    </>
    )
}