
import {
  Container, Row, Col, Card, CardText, CardBody,
  CardHeader, CardTitle, CardSubtitle, Badge
} from 'reactstrap';
import {
  Timeline,
  Events,
  TextEvent,
} from '@merc/react-timeline';

import { theme } from '../ExperimentPhasesTheme';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'
import { HashLink as Link } from 'react-router-hash-link';
import { useSelector, useDispatch } from 'react-redux'
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { useEffect, useState } from 'react';
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../../store/slices/experiments'
import MobileTimeline from '../../components/Timeline/MobileTimeline';

export const MobileExperimentDetails = (props: any) => {

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const isLogged = useSelector(AuthSelectors.isLogged);

  const FaseCard = ({ children }: any) => (
    <div>
      <span>{children} </span>
    </div>
  );

  const { content } = props;
  const dispatch = useDispatch();
  const experiment = useSelector(ExperimentsSelector.getCurrentPublicExperiment);
  const [liteExperiment, setLiteExperiment] = useState(null)

  useEffect(() => {
    //dispatch(ExperimentsActions.setCurrentPublicExperiment(null));
    if (content.timeline_pubbliche?.length > 0) {
      const id = content.timeline_pubbliche[0].id
      console.log(`ESPERIMENTO (TL) Timeline ${content.titolo} ID:`, id)
      dispatch(ExperimentsActions.willGetPublicExperiment(id));
    }
    //console.log("Content:", content)
    return () => { dispatch(ExperimentsActions.setCurrentPublicExperiment(null)); setLiteExperiment(null); }
  }, [])

  useEffect(() => {
    console.log("ESPERIMENTO (TL) AGGIORNATO:", experiment?.title)
    if (content.timeline_pubbliche?.length > 0 && experiment?.experiment == content.timeline_pubbliche[0].id) {
      setLiteExperiment(experiment)
    }
    else {
      if (content.timeline_pubbliche?.length > 0) {
        const id = content.timeline_pubbliche[0].id
        console.log(`ESPERIMENTO (TL) Timeline ${content.titolo} ID:`, id)
        dispatch(ExperimentsActions.willGetPublicExperiment(id));
      }
    }

  }, [experiment])

  const renderPublicTimeline = () => {
    if (liteExperiment != null) return (
      <MobileTimeline experiment={liteExperiment} footerHidden hideTitle disabled={!isLogged} />
    )
  }

  const renderRicercatori = () => {
    return content.ricercatori.map((ricercatore: string, index: number) => {
      return (
        <>
          <span key={ricercatore} style={{ margin: '2px', color: "#007bff" }}><small>{ricercatore}</small></span>
          <br></br>
        </>
      )
    })
  };


  const renderGradi = () => {
    return content.gradi.map((grado: string, index: number) => {
      return (
        <>
          <span key={grado} style={{ margin: '2px', color: "#007bff" }}><small>{grado}</small></span>
          <br></br>
        </>
      )
    })
  };

 

  const renderTags = () => {
    return content.tags.map((tag: string, index: number) => {
      return (
        <small>
          <span key={tag} style={{ margin: '2px', color: "#007bff" }}>{tag}{index < (content.tags.length - 1) && ", "}</span>
        </small>
      )

    })
  };

  const renderFasi = () => {
    if (content?.fasi == null) return null;
    return content.fasi.map((fase: string, index: number) => {
      return (<TextEvent key={index} date={""} text={fase} card={FaseCard} />)
    })
  };

  const renderAvailableLanguages = () => {
    return (
      <div style={{ marginBottom: "5px", display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: `${isLogged ? "timelines" : "calendar"}` }} >
            {renderTimelineFlags()}
          </Link>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: "interactive_sessions" }} >
            {renderInteractiveSessions()}
          </Link>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: "calendar" }} >
            {renderLiveSessionsFlags()}
          </Link>
        </div>


      </div>)
  }

  const renderTimelineFlags = () => {

    const timelines = content.timeline_pubbliche;
    if (timelines == null || timelines.length < 1) return null;
    const languages = new Set()
    for (let i = 0; i < timelines; i++) {
      if (timelines[i]["languages"] != null)
        languages.add(timelines[i]["languages"])
      else languages.add("IT");
    }
    if (languages.size < 1) { languages.add("IT"); }
    //console.log("LANGUAGES:::", languages)

    return (
      <div style={{ color: "white", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <u>{"Timeline"}</u>
        {Array.from(languages).map((language: any, index: any) => {
          return <Flag key={`lang_flag_${index}`} code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />
        })}
      </div>
    )
  }

  const renderInteractiveSessions = () => {
    //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    if (!content.interactive_devices || !content.interactive_devices[0]) return null;
    return (
      <div style={{ color: "white", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <u>{t(`Sessioni pratiche`)}</u>
      </div>
    )
  }

  const renderLiveSessionsFlags = () => {
    //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    if (!content.live) return null;
    const countries = content.languages || ["IT"];
    return (
      <div style={{ color: "white", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <u> {t(`Sessioni sincrone`)}</u>
        {countries.map((language: string) => {
          return <>
            <Flag code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />
          </>
        })}
      </div>
    )
  }

  const renderInfo = () => {
    return (<Container style={{ marginBottom: "10px" }}>
      <Row>
        <CardText>
          <b>{t("Periodo di erogazione")}</b>{": "}{`${content.periodo_erogazione[0]} - ${content.periodo_erogazione[1]}`}
        </CardText>
      </Row>
      <Row>
        <CardText>
          <b>{t("Durata")}</b>{": "}{`${content.durata} ${t("minuti")}`}
        </CardText>
      </Row>
      <Row>
        <CardText>
          <b>{t("Frequenza")}</b>{": "}{`${content.frequenza_mensile}`}
        </CardText>
      </Row>


    </Container>
    )
  }

  const renderSchedaEsperimento = () => {
    return content.url_scheda != null &&
      <CardText><a target="_blank" href={content.url_scheda}>
        Scarica la scheda completa dell'esperimento</a>
      </CardText>
  }

  return (<div style={{ display: "flex", flexDirection: "column" , 
  marginTop:"0px", marginBottom:"10px" }}>
    <div style={{textAlign:"center"}}>
      <div style={{ margin: "10px" }}><h4>{content.titolo}</h4></div>
    </div>

    <div style={{ marginTop: "5px" , marginBottom:"20px", textAlign: "left"}}>
      <CardSubtitle style={{ marginTop: "5px" }}><b>{t("Laboratorio")}</b></CardSubtitle>
      <div style={{ maxWidth: '100%', overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}>
        {content.laboratorio}
      </div>
    </div>

    {renderPublicTimeline()}
    {renderInfo()}


    <div style={{ marginTop: "5px", textAlign: "left" }}>
      <CardSubtitle style={{ marginTop: "5px" }}><b>{t("Parole chiave")}</b></CardSubtitle>
      <div style={{ maxWidth: '100%', overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}>
        {renderTags()}
      </div>
    </div>


    <div style={{ marginTop: "5px", marginBottom: "5px", textAlign: "left" }}>
      <CardSubtitle style={{ marginTop: "15px" }}><b>{t("Destinatari")}</b></CardSubtitle>
      <div style={{ maxWidth: '100%', overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}>
        {renderGradi()}
      </div>
    </div>

    <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "left" }}>
      <CardSubtitle style={{ textAlign: "left" }} ><b>{t("descrizione")}</b></CardSubtitle>
      <CardText dangerouslySetInnerHTML={{ __html: content.descrizione }} />
    </div>

    <div style={{ marginTop: "0px", marginBottom: "10px", textAlign: "left" }}>
      <div style={{ float: "right" }}>
        <CardSubtitle><i>{t("Ricercatori")}</i></CardSubtitle>
        {renderRicercatori()}
      </div>
    </div>

    <div style={{
      display: "flex", flexDirection: "column", justifyContent: "center",
      paddingTop: "5px", paddingBottom: "5px",
      border: '2px solid #000', marginTop: "5px", marginBottom: "5px"
    }}>
      <CardSubtitle style={{ margin: "10px", textAlign: "center" }} ><b>{t("Fasi dell'esperimento")}</b></CardSubtitle>
      <Timeline theme={theme}>
        <Events>
          {renderFasi()}
        </Events>
      </Timeline>
    </div>

    <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "left" }}>
      <CardSubtitle style={{ marginTop: "10px" }}><b>{t("Opportunità culturale del Laboratorio")}</b></CardSubtitle>
      <CardText dangerouslySetInnerHTML={{ __html: content.opportunita_culturale }} />
    </div>

  </div>)


}