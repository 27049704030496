import './MobileAccountMenu.css';  
import "./MobileAccountMenu.css"

import { useState } from 'react';
import {Button} from "reactstrap"
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from "react-redux";
import { RiAccountCircleFill } from "react-icons/ri";
import {  actions as AuthActions, selectors as AuthSelectors } from '../../store/slices/auth'
import { selectors as ProfileSelectors } from '../../store/slices/profile'
import { useTranslation } from 'react-i18next';

export const MobileAccountMenu = (props) => {
  // Stato per gestire il menu aperto o chiuso
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const { t } = useTranslation('frontend', { useSuspense: false });
  const userAttributes = useSelector(ProfileSelectors.getProfile)

  // Funzione per togglare il menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const renderLoggedOptions = () => {
    return (
        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
        <Button className='button-font' outline onClick={(ev) => {
                                  dispatch(push("/edit_profile"))
                                  setIsOpen(false)}}>{t("Profilo").toUpperCase()}</Button>
        {/*<hr className="separator" /> */}
        <Button  className='button-font'  outline onClick={(ev) => {
                                    dispatch(AuthActions.willLogoutUser());
                                    setIsOpen(false)}}>{t("Logout").toUpperCase()}</Button>
      </div>
    )
  }

  const renderUnloggedOptions = () => {
    return (
        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
        <Button className='button-font'  outline onClick={(ev) => {
                                  dispatch(push("/login"))
                                  setIsOpen(false)}}>{t("login").toUpperCase()}</Button>
      </div>
    )
  }

  return (
    <>
    {
      isLogged ?
        <button className="round-button" onClick={(ev)=>{toggleMenu()}}>
          {userAttributes?.given_name ? userAttributes.given_name[0].toLowerCase() : "?"}
        </button>
        : <RiAccountCircleFill className='account-button' onClick={(ev)=>{toggleMenu()}}/>
    }

     {/* Menu che appare quando isOpen è true */}
     {isOpen && (
        <div className="account-menu">
        {
            isLogged ? renderLoggedOptions() : renderUnloggedOptions()
        }
        </div>
      )}

  </>

     
   
  );
};
