import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  Alert, Container, Row, Col
} from 'reactstrap'

import { MobileExperimentDetails } from '../components/mobile/MobileExperimentDetails'
import { selectors as CatalogSelectors, actions as CatalogActions } from '../store/slices/catalog'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CalendarPage } from './calendar';

import { selectors as AuthSelectors } from '../store/slices/auth'
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'
import MobileContainer from '../components/mobile/MobileContainer';

export const MobileExperimentDetailsPage = () => {

  let { experiment }: any = useParams();
  const dispatch = useDispatch();
  const currentExperiment = useSelector(CatalogSelectors.getCurrentExperiment);
  const isLogged = useSelector(AuthSelectors.isLogged);
  const catalog = useSelector(CatalogSelectors.getCatalog);
  const { t } = useTranslation('frontend', { useSuspense: false });
 
  //console.log("Current experiment:", currentExperiment);
  React.useEffect(() => {
    //console.log("Carico l'esperimento con id:", experiment);
    dispatch(CatalogActions.willGetExperiment(experiment))

    return () => { }
  }, [experiment]);

  


  React.useEffect(() => {
    //console.log("Carico l'esperimento (a causa di aggiornamento Language) con id:", experiment);
    dispatch(CatalogActions.willGetExperiment(experiment))

    return () => { }
  }, [catalog]);

  

  const timelinePubbliche = (currentExperiment != null &&
    currentExperiment.timeline_pubbliche != null) ?
    currentExperiment.timeline_pubbliche : [];

  //  [{"id":"1234", "descrizione" : "descrizione!"}] as any;


  const linkFormatter = (cell: any, row: any) => {
    const languages = row.languages || ["IT"]
    return <div style={{ display: "flex" }}>
      {
        languages.map((language: any) => {
          return (<Flag code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />)
        })
      }
     {row?.id!=null && row.id.length>0 ?
      <a href={`/public/${row.id}?lite=true`}>{row.descrizione}</a> :
      <span><i>{t("prossima pubblicazione")}</i></span>
    }
     
    </div>

  }
 
  const renderContainer = () => {
    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            {currentExperiment != null && <MobileExperimentDetails content={currentExperiment} />}
          </Col>
        </Row>

       
        <div id="calendar">
          {isLogged ? (
            <Alert color="warning" >
                {t("calendar_mobile_access_warning")}
            </Alert>
          ) :
            <Alert color="warning" className="text-center">
              <Link to="/login">
                {t("alert_access_to_timeline")}
              </Link>
            </Alert>
          }
        </div>
      </Container>)
  }

  return (
    <MobileContainer key={`container_experiment:${experiment || "0"}`}>
        {renderContainer()}
    </MobileContainer>
  )
}