import React, { useState } from 'react';
import './HamburgerMenu.css'; // Puoi aggiungere i tuoi stili qui
import { Button } from "reactstrap"
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from "react-redux";
import LanguageSelector from '../LanguageSelector';
import { useTranslation } from 'react-i18next';
import { IconContext } from "react-icons";
import { RxHamburgerMenu } from "react-icons/rx";
import { RiCloseLine } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { IconButton } from "@material-ui/core";

const HamburgerMenu = (props) => {
  // Stato per gestire il menu aperto o chiuso
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('frontend', { useSuspense: false });

  // Funzione per togglare il menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const renderMenu = () => {
    return (<div className="menu">

      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
        <div style={{ display:"flex" , justifyContent:"flex-end" }}>
          <LanguageSelector />
        </div>
        <hr className="separator" />
        <Button className='button-font' outline onClick={(ev) => {
          dispatch(push("/info"))
          setIsOpen(false)
        }}>{t("menu_mobile_project_info").toUpperCase()}</Button>
        
        <Button className='button-font' outline onClick={(ev) => {
          dispatch(push("/"))
          setIsOpen(false)
        }}>{t("menu_mobile_catalog").toUpperCase()}</Button>
      
        <Button className='button-font' outline onClick={(ev) => {
          dispatch(push("/labs"))
          setIsOpen(false)
        }}>{t("menu_mobile_labs").toUpperCase()}</Button>
      </div>
    </div>)
  }

  return (
    <div className="hamburger-menu">
      {/* Pulsante hamburger */}
      {
        isOpen ? 
          <button className="hamburger-icon" onClick={toggleMenu}>
             <RiCloseLine />
            </button>
        :
        <button className="hamburger-icon" onClick={toggleMenu}>
        <RxHamburgerMenu /> {/* Questo è l'icona del menu hamburger */}
      </button>

      }
     

      {/* Menu che appare quando isOpen è true */}
      {isOpen && (
        renderMenu()
      )}
    </div>
  );
};

export default HamburgerMenu;
