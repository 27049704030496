import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { map, includes, flow } from 'lodash'

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState: {
    catalog: [],
    experiments:{},
    labs : {},
    currentOrganizationExperiments: [],
    currentExperiment: null,
    currentExperimentWithIDevice: null,
    filteredIds: null as any,
    currentSharedExperiments : []
  }
  ,
  reducers: {
    willLoadCatalog: (state, action: PayloadAction<any>) => state,
    willLoadCurrentSharedExperiments: (state, action: PayloadAction<any>) => state,
    setFilteredIds: (state, action: PayloadAction<any>) => {
      state.filteredIds = map(action.payload, 'id') as any;
    },
    clearFilter: (state, action: PayloadAction<any>) => {
      //console.log('in clear filter')
      state.filteredIds = null;
    },
    didLoadCatalog: (state, action: PayloadAction<any>) => {
      state.catalog = action.payload;
      //console.log("Catalogo aggiornato (filtered):", state.catalog);
    },
    willLoadExperiments: (state, action: PayloadAction<any>) => state,
    willLoadOrganizationExperiments: (state, action: PayloadAction<any>) => state,
    willGetExperiment: (state, action: PayloadAction<any>) => state,

    setCurrentExperiment: (state, action: PayloadAction<any>) => {
      //console.log("current experiment set slice");
      state.currentExperiment = action.payload;
    },

    setCurrentExperimentWithIDevice: (state, action: PayloadAction<any>) => {
      state.currentExperimentWithIDevice = action.payload;
    },


    setCurrentSharedExperiments: (state, action: PayloadAction<any>) => {
      state.currentSharedExperiments = action.payload;
    },

    setExperiments: (state, action: PayloadAction<any>) => {
      state.experiments = action.payload;
    },

    setLabs: (state, action: PayloadAction<any>) => {
      state.labs = action.payload;
    },

    setCurrentOrganizationExperiments: (state, action: PayloadAction<any>) => {
      state.currentOrganizationExperiments = action.payload;
    },

    didGetExperiment: (state, action: PayloadAction<any>) => {
       (state.experiments as any)[action.payload.id] = action.payload; }
  
  }
});


export const { actions, reducer }: any = catalogSlice


export const getExperimentById = (experimentList:Array<any>, id:string) => {
  //console.log("Experiment list:", experimentList);
  //console.log("Cerco l'esp con id", id);
  for (let i=0;i<experimentList.length;i++)
  { //console.log("Considero l'esperimento:",experimentList[i]);
    if (experimentList[i]!=null && experimentList[i]["id"]==id) return experimentList[i]; }
  return null;
}

export const selectors = {
  getExperiment: (id: any) => (state: any) => {
    //console.log("dashboard catalog experiments:", state.catalog.experiments);
    const experiment = state.catalog.experiments[id];
    return experiment;
  },

  getTopic: (id: any) => (state:any) =>{
    return state.catalog.catalog.find((topic:any)=>{
      return (topic["id"]==id)
    })
  },

  getCatalog: (state: any) => { 
    //console.log("catalog selector:", state.catalog.catalog);
    return state.catalog.catalog},
  getFilteredIds: (state: any) => state.catalog.filteredIds,

  getExperiments: (state: any) => {
    ////console.log("catalog getExperiments:", state.catalog.experiments);
    return state.catalog.experiments;
  },

  getLabs: (state: any) => {
      return state.catalog.labs;
  },

  getLiveExperiments: (state: any) => {
    //https://masteringjs.io/tutorials/fundamentals/filter-object
    return flow([
      Object.entries,
      arr => arr.filter(([key, value]:any) => value["live"]),
      Object.fromEntries
    ])(state.catalog.experiments);
  },

  getExperimentsWithInteractiveDevices: (state:any) =>
  {
    return Object.values(state.catalog.experiments).filter(
      (experiment:any) => experiment.interactive_devices?.length>0 );
    
  },

  getCurrentExperiment: (state: any) => {
    return state.catalog.currentExperiment;
  },

  getCurrentExperimentWithIDevice: (state: any) => {
    return state.catalog.currentExperimentWithIDevice;
  },
  
  getCurrentOrganizationExperiments:  (state: any) => {
    
    return  state.catalog.currentOrganizationExperiments
  },
  getFilteredCatalog: (state: any) => createSelector([selectors.getCatalog, selectors.getFilteredIds], (catalog, filteredIds) => {
    //console.log('filtered catalog: ', catalog, filteredIds)
    let results: any = [];
    if(filteredIds === null) return catalog //return catalog if search query is empty

    for (const topic of catalog) {
      ////console.log("Catalog topic exp:", topic.esperimenti);
      const newTopicExperiments: any = []
      for (const experiment of topic.esperimenti) {
        //console.log("Analisi_esperimento:",experiment)
        if ((experiment.type!="path" && includes(filteredIds, experiment.id)) || 
        (experiment.type=="path" && filteredIds.filter((value:any) =>
         experiment.esperimenti.map((ob:any) => ob["id"]).includes(value)).length>0))
     
        {
          //console.log('match::: ', filteredIds, experiment.id, experiment)
          newTopicExperiments.push(experiment);

        }
      }
      if (newTopicExperiments.length > 0) 
        {results.push({ ...topic, esperimenti: newTopicExperiments })}
    }
    //console.log('filtered result: on', filteredIds, results)
    return results;
  })
}