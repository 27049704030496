import { useParams } from 'react-router-dom'
import { MobileTopic } from '../components/mobile/MobileTopic';
import { selectors as CatalogSelector } from '../store/slices/catalog'
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import MobileContainer from '../components/mobile/MobileContainer';

export const MobileTopicPage = (props) => {
    const { id } = useParams();
    const myTopic = useSelector(CatalogSelector.getTopic(id));

    useEffect(()=>{
        console.log("Instanziato topic con id:", id);
    }, [])
    

    return (
        <MobileContainer>
            <MobileTopic content={myTopic}/>
        </MobileContainer>
   
)
}