import React from 'react';
import './MobileContainer.css';
import HamburgerMenu from './HamburgerMenu';
import { useTranslation } from 'react-i18next';

import { MobileAccountMenu } from './MobileAccountMenu';
import LanguageSelector from '../LanguageSelector';

const MobileContainer = ({ children }) => {
  const { t } = useTranslation('frontend', { useSuspense: false });

  return (
    <div className="App">
      {/* Header con immagine principale */}
      <header className="header-container">
        <div className="account-button-container">
          <MobileAccountMenu />
        </div>

        <img
          src="/riale_eu_header_no_loghi.png"
          alt="Header"
          className="header-image"
        />

        <img
          src="/logo RIALE EU_tr.png"
          alt="Left"
          className="overlay-image left"
        />

        <img
          src="/logo_crs4_tr.png"
          alt="Right"
          className="overlay-image right"
        />
        <HamburgerMenu />
      </header>

      {/* Contenuto della pagina */}
      <main className="content">
        {children}
      </main>

      {/* Footer con immagine */}
      <footer className="footer-container">
        <img className='footer-banner'
          src="/banner_loghi_no_crs4.png"
          alt="Footer"
        />
        <div className="image-container">
          <div className="overlay-text">{t("footer_info")}</div>
        </div>
      </footer>
      <LanguageSelector hidden/>
    </div>
  );
}


export default MobileContainer;
